import React, { useState, useEffect } from "react";
import Publicaciones_publicacion from "../subcomponentes/Publicaciones_publicacion";
// import "../../css/publicaciones.css";
import { NavLink } from "react-router-dom";

const Publicaciones = ({ idioma }) => {
  const [publications, setPublications] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [texto, setTexto] = useState({});

  useEffect(() => {
    const fetchPublications = async () => {
      try {
        const response = await fetch("https://api.ideiageoca.org/api/publications");
        if (!response.ok) {
          throw new Error('La API no responde.');
        }
        const data = await response.json();
        setPublications(data);
        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };
    fetchPublications();
    if (idioma) {
      setTexto({
        publicaciones: "Publications",
        Coordinador: "Coordinator:",
        Contacto: "Contact:",
      });
    } else {
      setTexto({
        publicaciones: "Publicaciones",
        Coordinador: "Coordinador:",
        Contacto: "Contacto:",
      });
    }
  }, [idioma]);

  const groupByYear = (publications) => {
    return publications.reduce((acc, publication) => {
      const year = publication.year;
      if (!acc[year]) {
        acc[year] = [];
      }
      acc[year].push(publication);
      return acc;
    }, {});
  };

  const groupedPublications = groupByYear(publications);
  const sortedYears = Object.keys(groupedPublications).sort((a, b) => b - a);

  return (
    <div className="animate__animated animate__fadeIn">
      <section class="intro-single">
        <div class="container">
          <div class="row">
            <div class="col-md-12 col-lg-8">
              <div class="title-single-box">
                <h1 class="title-single">{texto.publicaciones}</h1>
              </div>
            </div>
            <div class="col-md-12 col-lg-4">
              <nav aria-label="breadcrumb" class="breadcrumb-box d-flex justify-content-lg-end">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                    <NavLink exact to="/">
                      Home
                    </NavLink>
                  </li>
                  <li class="breadcrumb-item active" aria-current="page">
                    {texto.publicaciones}
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </section>
      <section class="contact">
        <div class="container">
          <div class="row">
            <div class="col-sm-12">
              <div class="contact-map box">
                <div id="map" class="contact-map">
                  {sortedYears.map((year) => (
                    <div key={year}>
                      <h3 className="anio">{year}</h3>
                      <ul>
                        {groupedPublications[year].map((publication) => (
                          <Publicaciones_publicacion
                            encabezado1={publication.title}
                            encabezado2={publication.cite}
                            enlace={publication.doi}
                          ></Publicaciones_publicacion>
                        ))}
                      </ul>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <br />
    </div>
  );
};

export default Publicaciones;
